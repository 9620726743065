import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import Sidebar from './components/Sidebar';

import routes from "./routes";


function App() {

  return (
    <div>
      {/* Navigation Sidebar */}
      <Sidebar />

      {/* Content */}
      <div  className="w3-main" style={{margin: '0px 40px 0px 340px'}}>
        <BrowserRouter basename={process.env.REACT_APP_BASENAME || ""}>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            );
          })}
        </BrowserRouter>

        {/* Footer */}
        <div className="w3-light-grey w3-container w3-padding-32" style={{margin:'75px -40px 0px -40px', padding:'0px 88px 0px 0px'}}>
          <p className="w3-right-align">
            Impuls.Ing GmbH | Wassergrabe 6 | 6210 Sursee<br></br>
            041 508 16 24<br></br>
            <span className="w3-small"><a href="/datenschutz">Datenschutz</a>  [Build dd6004c]</span>
          </p>
        </div>
      </div>

    </div>
  );
};

export default App;
